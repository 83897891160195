<template>
<div class="c-alert" :class="`${!active && 'opacity-0 pointer-events-none'}`" @keyup.esc="closeEsc" tabindex="0">
  <div class="c-alert-overlay">
    <div class=" relative p-4 max-w-sm mx-auto w-full bg-white shadow-md rounded-md z-50">
      <div class="c-alert-close" @click="closeButton"> <i class="fal fa-times-circle"></i> </div>
      <div class="flex justify-center items-center pb-4">
        <span class="text-gray-700 font-semibold text-2xl">Title</span>
      </div>
      <div class="c-contents">
        <slot></slot>
      </div>
      <div class="text-center pt-4">
        <Button @click="confirm()">Okay</Button></div>
    </div>
  </div>
</div>
</template>

<script>

import Button from "./Button"

export default {
  name: "Alert",
  components: {
    Button
  },
  props: {

  },
  data(){
    return {
      active: false
    }
  },
  methods: {
    closeEsc(){
      this.active = false
    },
    closeButton(){
      this.$emit('close')
      this.active = false
    },
    confirm(){
      this.$emit('confirm')
      this.active = false
    },
    open(){
      this.$emit('open')
      this.active = true
    }
  }
}
</script>

<style scoped>
.c-alert{
  @apply fixed bg-gray-400 p-0 top-0 bottom-0 left-0 right-0 z-40 bg-opacity-75 transition-opacity duration-300 ease-in-out
}
.c-alert-overlay{
  @apply flex justify-center items-center h-screen bg-gray-400 px-6 bg-opacity-50
}
.c-alert-close{
  @apply text-gray-600 absolute mt-1 top-0 right-0 mr-2 cursor-pointer
}
</style>
