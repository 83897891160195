<template>
<div class="c-dialog" :class="`${!active && 'opacity-0 pointer-events-none'}`" @keyup.esc="closeEsc" tabindex="0">
  <div class="c-dialog-overlay">
    <div class="c-dialog-content" :class="[_size]">
      <div class="c-dialog-close" @click="closeButton"> <i class="fal fa-times-circle"></i> </div>
      <div class="flex justify-center items-center pb-4">
        <span class="text-gray-700 font-semibold text-2xl">{{ title }}</span>
      </div>
      <div class="c-contents">
        <slot></slot>
      </div>
      <div class="text-center pt-4 flex justify-around">
        <Buttons>
          <Button @click="confirm()">{{ confirmLabel || 'Okay' }}</Button>
          <Button type="blank" @click="abort()">Abbrechen</Button>
        </Buttons>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import Button from "./Button"
import Buttons from "./Buttons"

export default {
  name: "Dialog",
  components: {
    Button,
    Buttons,
  },
  props: {
    size: String,
    confirmLabel: String,
    title: String
  },
  data(){
    return {
      active: false
    }
  },
  methods: {
    closeEsc(){
      this.active = false
    },
    closeButton(){
      this.$emit('close')
      this.active = false
    },
    confirm(){
      this.$emit('confirm')
      this.active = false
    },
    abort(){
      this.$emit('abort')
      this.active = false
    },
    open(){
      this.$emit('open')
      this.active = true
    }
  },
  computed: {
    _size(){
      switch (this.size){
        case    'wide':      return 'max-w-2xl'
        case    'default':
        default:             return 'max-w-sm'
      }
    }
  }
}
</script>

<style scoped>
.c-dialog{
  @apply fixed bg-gray-400 p-0 top-0 bottom-0 left-0 right-0 z-40 bg-opacity-75 transition-opacity duration-150 ease-in-out
}
.c-dialog-overlay{
  @apply flex justify-center items-center h-screen bg-gray-400 px-6 bg-opacity-50
}
.c-dialog-content{
  @apply relative p-4 mx-auto w-full bg-white border border-gray-500 shadow-lg rounded-md z-50
}
.c-dialog-close{
  @apply text-gray-600 absolute mt-1 top-0 right-0 mr-2 cursor-pointer
}
</style>
