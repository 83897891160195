
export const session = new Object({
    name: null,
    type: "WORKSHOP",
    speaker: "",
    locationId: "",
    description: "",
    isMandatory: false,
    date: "",
    timeSlotId: null,
    groupId: null,
    maxGuests: null
})


export async function getSession (id) {
    if (this.debug) {
        console.log('getSession triggered with', id)
    }
    return await this.api().getRequest('/sessions/'+id, false, (e) => {
        this.state.session = Object.assign({}, e)
    })
}

export async function deleteSession (id) {
    if (this.debug) {
        console.log('deleteSession triggered with', id)
    }
    return await this.api().deleteRequest('/sessions/'+id, () => {
        clearSession()
    })
}

export async function createSession (session) {
    if (this.debug) {
        console.log('createSession triggered with', name)
    }
    let res
    await this.api().postRequest('/events/'+ this.state.event.id  +'/sessions', session, (resc) => {
        res = resc
        this.getSessions()
    })
    return res.id
}

export async function updateSession (session = {}) {
    if (this.debug) {
        console.log('updateSession triggered with', name)
    }
    return await this.api().putRequest('/sessions/'+this.state.session.id, session, () => {
        this.getSessions()
        return true
    })
}

export function clearSession() {
    if (this.debug) {
        console.log('clearSessions triggered')
    }
    this.state.session = Object.assign({}, session)
}

const sessions = []

export async function getSessions () {
    if (this.debug) {
        console.log('getSessions triggered')
    }
    await this.api().getRequest('/events/'+ this.state.event.id  +'/sessions', false, (e) => {
        this.state.sessions = e
    })
}

export function clearSessions() {
    if (this.debug) {
        console.log('clearSessions triggered')
    }
    this.state.sessions = sessions
}

