const recipients = []

export async function getRecipients () {
    if (this.debug) {
        console.log('getRecipients triggered')
    }
    let listId = 0
    if (typeof this.state.event.mwSubscriberListId === 'undefined'){
        this.state.recipients = recipients
        return
    }
    listId = parseInt(this.state.event.mwSubscriberListId)
    if (listId <= 0){
        this.state.recipients = recipients
        return
    }
    await this.api().getRequest('/recipients/'+listId, false, (e) => {
        this.state.recipients = e
    })
}

export function clearRecipients() {
    if (this.debug) {
        console.log('clearRecipients triggered')
    }
    this.state.recipients = recipients
}

