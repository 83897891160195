<template>
  <label class="block c-field-content">
    <label class="inline-flex items-center px-3 py-2 mt-1">
      <input type="checkbox" class="form-checkbox" checked v-model="value">
      <span class="ml-2"></span>
    </label>
  </label>
</template>
<script>
export default {
  name: "Input",
  props: {
    modelValue: String
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
