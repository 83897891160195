<template>
  <div class="c-toolbar flex ">
    <slot></slot>
    <div class="actions flex-grow text-right">
      <div class="flex justify-end">
        <div v-for="(act, index) in actions" :key="index" class="action pl-2">
          <Button v-if="act.component === 'button'" :title="act.title" :type="act.type" @click=" act.action" v-html="act.label"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button'

export default {
  name: "Toolbar",
  components: {
    Button
  },
  props: {
    actions: Array
  },
  computed: {

  }
}
</script>

<style scoped>
.c-toolbar{
  @apply bg-white px-4 py-2 border rounded mb-2
}
</style>
