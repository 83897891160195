<template>
  <div class="c-card">
    <div class="c-card-wrapper">
      <div class="c-card-head">
        <slot name="head"></slot>
      </div>
      <div class="c-card-body">
        <slot></slot>
      </div>
      <div class="c-card-foot">
        <slot name="foot"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Card",
  props: {
  },
  computed: {

  }
}
</script>

<style scoped>
.c-card{
  @apply max-w-sm w-full
}
.c-card-wrapper{
  @apply rounded bg-white overflow-hidden shadow-sm border  border-gray-400
}

.c-card-head{
  @apply p-4 border-b font-bold
}

.c-card-body{
  @apply p-4
}

.c-card-foot{
  @apply p-4 border-t
}

.c-card-foot:empty,
.c-card-head:empty,
.c-card-body p:empty{
  @apply hidden
}

@screen sm{
  .c-card{
    @apply mr-6
  }
}
</style>
