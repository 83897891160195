export const timeslot = new Object({
    start: null,
    end: null
})


export function getTimeslot (id) {
    if (this.debug) {
        console.log('pullTimeslot triggered with', id)
    }
    return this.api().getRequest('/timeslots/'+id, false, (e) => {
        this.state.timeslot = Object.assign({}, e)
    })
}

export async function deleteTimeslot (id) {
    if (this.debug) {
        console.log('deleteTimeslot triggered with', id)
    }
    return await this.api().deleteRequest('/timeslots/'+id, () => {
        clearTimeslot()
    })
}

export async function createTimeslot (timeslot) {
    if (this.debug) {
        console.log('createTimeslot triggered with', name)
    }
    let res
    await this.api().postRequest('/events/'+ this.state.event.id  +'/timeslots', timeslot, (resc) => {
        res = resc
        this.getTimeslots()
    })
    return res.id
}

export async function updateTimeslot (timeslot = {}) {
    if (this.debug) {
        console.log('updateTimeslot triggered with', name)
    }
    return await this.api().putRequest('/timeslots/'+this.state.timeslot.id, timeslot, () => {
        return true
    })
}

export function clearTimeslot() {
    if (this.debug) {
        console.log('clearTimeslot triggered')
    }
    this.state.timeslot = Object.assign({
        _set: false
    }, timeslot)
}

const timeslots = []

export async function getTimeslots () {
    if (this.debug) {
        console.log('pullTimeslots triggered')
    }
    await this.api().getRequest('/events/'+ this.state.event.id  +'/timeslots', false, (e) => {
        this.state.timeslots = e
    })
}

export function clearTimeslots() {
    if (this.debug) {
        console.log('clearTimeslots triggered')
    }
    this.state.timeslots = timeslots
}

