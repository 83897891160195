<template>
  <label class="block c-field-content">
    <textarea v-if="type === 'multiline'" class="form-textarea mt-1 block w-full" rows="3" v-model="value" :placeholder="placeholder"></textarea>
    <input v-else :type="type" class="form-input mt-1 block w-full" v-model="value" :placeholder="placeholder">
  </label>
</template>
<script>
export default {
  name: "Input",
  props: {
    type: String,
    placeholder: String,
    modelValue: String
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
