<template>
  <label class="block c-field-content">
    <select class="form-select block w-full mt-1" v-model="value">
      <option v-if="_options.length === 0" :value="undefined" disabled selected hidden>Bitte wählen</option>
      <option v-for="(option, index) in _options" :key="index" :value="option.value">{{option.label}}</option>
    </select>
  </label>
</template>
<script>
export default {
  name: "Test",
  props: {
    options: Array,
    modelValue: Number
  },
  computed: {
    _options(){
      if(this.options.length === 0) return []
      if(typeof this.options[0] === 'string'){
        return this.options.map(o => new Object({value: o, label: o}))
      }
      if(typeof this.options[0] === 'object') return this.options
      return []
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
