<template>
  <div class="c-cards flex flex-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "Cards"
}
</script>

<style scoped>
  .c-cards {
    @apply -mb-3
  }

 .c-cards /deep/ .c-card{
   @apply mr-3 mb-3
 }
 .c-cards /deep/ .c-card:last-child{
   @apply mr-0
 }
</style>
