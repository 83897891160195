export const event = new Object({
    id: null,
    name: '',
    slug: '',
    mwSubscriberListId: null,
    startDate: '',
    endDate: '',
    settingsId: null,
    internalNotes: null,
    maxGuestCount: null,
    status: null
})


export function getEvent (id) {
    if (this.debug) {
        console.log('pullEvent triggered with', id)
    }
    return this.api().getRequest('/events/'+id, false, (e) => {
        this.state.event = Object.assign({}, e)
    })
}

export async function deleteEvent (id) {
    if (this.debug) {
        console.log('deleteEvent triggered with', id)
    }
    return await this.api().deleteRequest('/events/'+id, () => {
        clearEvent()
    })
}

export async function createEvent (event) {
    if (this.debug) {
        console.log('createEvent triggered with', name)
    }
    let res
    await this.api().postRequest('/events', event, (resc) => {
        res = resc
        this.getEvents()
    })
    return res.id
}

export async function updateEvent (event = {}) {
    if (this.debug) {
        console.log('updateEvent triggered with', name)
    }
    return await this.api().putRequest('/events/'+this.state.event.id, event, () => {
        return true
    })
}

export function clearEvent() {
    if (this.debug) {
        console.log('clearEvents triggered')
    }
    this.state.event = Object.assign({
        _set: false
    }, event)
}

const events = []

export async function getEvents () {
    if (this.debug) {
        console.log('pullEvents triggered')
    }
    await this.api().getRequest('/events', false, (e) => {
        this.state.events = e
    })
}

export function clearEvents() {
    if (this.debug) {
        console.log('clearEvents triggered')
    }
    this.state.events = events
}

