function setTokens(jwt, rjwt){
    sessionStorage.setItem('etf-jwt', String(jwt))
    sessionStorage.setItem('etf-rjwt', String(rjwt))
}
function setEnv(sid, accid, paccid){
    sessionStorage.setItem('etf-sid', String(sid))
    sessionStorage.setItem('etf-accid', String(accid))
    sessionStorage.setItem('etf-paccid', String(paccid))
}
function unsetTokens(){
    sessionStorage.removeItem('etf-jwt')
    sessionStorage.removeItem('etf-rjwt')
}
export function getJWT(){
    return sessionStorage.getItem('etf-jwt')
}
export function getSID(){
    return sessionStorage.getItem('etf-sid')
}
function clearSID(){
    return sessionStorage.removeItem('etf-sid')
}
export function getACCOUNT(){
    return sessionStorage.getItem('etf-accid')
}
export function getPACCOUNT(){
    return sessionStorage.getItem('etf-paccid')
}
function clearPACCOUNT(){
    return sessionStorage.removeItem('etf-paccid')
}
function clearACCOUNT(){
    return sessionStorage.removeItem('etf-accid')
}
export function getRJWT(){
    return sessionStorage.getItem('etf-rjwt')
}
function clearAuth(){
    unsetTokens()
    clearPACCOUNT()
    clearACCOUNT()
    clearSID()
}

export function getAuth(){

}


const auth = {
    status: false
}

export function loginSession (sid = '', account = '', pAccount = '') {
    if (this.debug) {
        console.log('loginSession triggered')
    }
    return this.api().postRequest('/authorization/createTokenByMwSession', { accountId: Number(account), sessionId: sid }, (res) => {
        setEnv(String(sid), String(account), String(pAccount))
        setTokens(res.access_token, res.refresh_token)
        if (this.debug) console.log('set refresh interval')
        setTimeout(refreshToken, (1000 * 60 * 45))
    }, false)
}

export function refreshToken () {
    if (this.debug) {
        console.log('refreshToken triggered')
    }
    return this.api().postRequest('/authorization/createTokenByRefreshToken', { refresh_token:  getRJWT()}, (res) => {
        setTokens(res.access_token, res.refresh_token)
        if (this.debug) console.log('set refresh interval')
        setTimeout(refreshToken, (1000 * 60 * 45))
    }, false)
}

export function logout(){
    if (this.debug) {
        console.log('logout triggered')
    }

    clearAuth()
    this.state.auth = auth
}

export async function authorized(){
    if (this.debug) {
        console.log('authorized triggered')
    }
    let ret = false
    // await this.api().getRequest('/user', false, () => {ret = true}).then(() => {
    //     console.log(ret)
    //     if (ret === false) this.refreshToken()
    // })

    return ret
}

export async function initAuth(){
    if (this.debug) {
        console.log('initAuth triggered')
    }
    let authorization = Object.assign({}, auth)

    // await this.refreshToken()

    await this.authorized()

    this.state.auth = authorization

    return Promise.resolve()
}


