export const landingpage = new Object({
    isPublic: true,
    disabledAt: null,
    title: null,
    elements: []
})



export async function getLandingpage (id) {
    if (this.debug) {
        console.log('getLandingpage triggered with', id)
    }
    return await this.api().getRequest('/landingpages/'+id, false, (e) => {
        this.state.landingpage = Object.assign({}, e)
    })
}

export async function deleteLandingpage (id) {
    if (this.debug) {
        console.log('deleteLandingpage triggered with', id)
    }
    return await this.api().deleteRequest('/landingpages/'+id, () => {
        clearLandingpage()
    })
}

export async function createLandingpage (landingpage) {
    if (this.debug) {
        console.log('createLandingpage triggered with', name)
    }

    let res
    await this.api().postRequest('/events/'+ this.state.event.id  +'/landingpages', landingpage, (resc) => {
        res = resc
        this.getLandingpages()
    })
    return res.id
}

export async function updateLandingpage (landingpage = {}) {
    if (this.debug) {
        console.log('updateLandingpage triggered with', name)
    }
    return await this.api().putRequest('/landingpages/'+landingpage.id, landingpage, () => {
        this.getLandingpages()
        return true
    })
}

export function clearLandingpage() {
    if (this.debug) {
        console.log('clearLandingpage triggered')
    }
    this.state.landingpage = Object.assign({}, landingpage)
}

const landingpages = []

export async function getLandingpages () {
    if (this.debug) {
        console.log('getLandingpages triggered')
    }
    await this.api().getRequest('/events/'+ this.state.event.id  +'/landingpages', false, (e) => {
        this.state.landingpages = e
    })
}

export function clearLandingpages() {
    if (this.debug) {
        console.log('clearLandingpages triggered')
    }
    this.state.landingpages = landingpages
}

