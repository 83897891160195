<template>
<div class="c-field">
  <div class="c-field-label">{{label}}</div>
  <div class="c-field-content">
    <slot></slot>
  </div>
</div>
</template>

<script>

export default {
  name: "Field",
  props: {
    label: String
  },
  data(){
    return {
    }
  }
}
</script>

<style scoped>
.c-field{
  @apply flex w-full flex-col
}
.c-field-label{
  @apply pt-3 w-48
}
@screen md {
  .c-field{
    @apply flex-row
  }
  .c-field-label{
    @apply py-3 w-64
  }
}
.c-field-content{
  @apply flex-1
}
.c-field-content /deep/ p{
  @apply mt-1 py-2 px-3
}
.c-field-content /deep/ .c-field-content{
  @apply
}

</style>
